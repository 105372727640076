const BASE_URL = process.env.PUBLIC_URL;

const API_SUB_PATH = "/api";

const YOUTUBE_URL="https://www.youtube.com/watch?v=-YQi9n06R10";
const LINKEDIN_URL = "https://www.linkedin.com/company/troescorp";

const createApiUrl = (endpoint) => `${BASE_URL}${API_SUB_PATH}${endpoint}`;

const LOGIN = createApiUrl('/login');
const LOGOUT = createApiUrl('/logout');

const SYSTEMS = createApiUrl('/system');

const URLS = {
  BASE_URL,
  YOUTUBE_URL,
  LINKEDIN_URL,
  LOGIN,
  LOGOUT,
  SYSTEMS,
};

export default URLS;
