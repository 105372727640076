import React, { createContext, useContext, useState } from 'react';
import request from '../utils/helpers/apiHandler';
import URLS from '../utils/lib/urls';

export const SystemsContext = createContext();

export const useSystems = () => {
  return useContext(SystemsContext);
};

export const SystemsProvider = ({ children }) => {
  const [systems, setSystems] = useState([]);
  const [selectedSystem, setSelectedSystem] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getSystems = async () => {
    setLoading(true);
    const { data, error } = await request('GET', URLS.SYSTEMS);    

    setSystems(data?.systemDetails || []);
    setError(error);
    setLoading(false);
  };

  return (
    <SystemsContext.Provider value={{
      loading, error, systems, selectedSystem,
      getSystems, setSelectedSystem
    }}>
      {children}
    </SystemsContext.Provider>
  );
};
