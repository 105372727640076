import React from "react";
import { useSystems } from "../contexts/SystemsContext";

function Home() {
  const { selectedSystem } = useSystems();

  return (
    <div className="main home h-full relative">
      <img
        alt=""
        src="images/home-bg.jpg"
        className="h-full w-full absolute top-0 left-0 bg-cover bg-center bg-no-repeat brightness-50"
      ></img>
      <div className="h-full w-full absolute top-0 left-0 bg-gradient-to-t from-transparent via-transparent to-slate-900"></div>
      <div className="relative font-extrabold text-4xl md:text-6xl lg:text-6xl xl:text-6xl mt-10">
        Welcome to the TROES Client Portal!
      </div>
      <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row relative h-4/5 items-center z-10">
        <div className="font-bold text-l md:text-xl lg:text-3xl xl:text-3xl w-full md:w-[60%] lg:w-[60%] xl:w-[60%]">
          <div className="mt-4 md:my-3 lg:my-5 xl:my-5">
            We're excited to have you here. This is your personalized space to
            stay updated on the progress of your projects. Explore real-time
            insights, track milestones, and manage your energy storage solutions
            with ease. If you have any questions or need assistance, our team is
            here to help.
          </div>
          <br />
          <div>
            Thank you for choosing TROES as your energy partner—we're committed
            to powering your success!
          </div>
        </div>
        <div className="py-1 flex flex-col w-full md:w-[40%] lg:w-[40%] xl:w-[40%] items-center">
          <a
            role="button"
            disabled={!selectedSystem}
            href={selectedSystem?.request_portal_link}
            className="blue-btn my-4 ms-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            Go to Service Request Portal
          </a>
          <a
            role="button"
            disabled={!selectedSystem}
            href={selectedSystem?.grafana_link}
            className="blue-btn my-4 ms-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            
              Go to Monitoring Dashboard
            
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;
