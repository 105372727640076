import Dropdown from "../Dropdown/Dropdown";
import { useEffect } from "react";
import { useSystems } from "../../contexts/SystemsContext";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { useAuth } from "../../contexts/AuthContext";

function Navbar() {
  const { logout, authData } = useAuth();
  const { loading, systems, selectedSystem, getSystems, setSelectedSystem } =
    useSystems();

  useEffect(() => {
    if (systems && systems.length) setSelectedSystem(systems[0]);
  }, [loading]);

  useEffect(() => {
    getSystems();
  }, []);

  return (
    <div className="navbar bg-troesBlue px-14 py-6">
      <div className="flex-1">
        <img className="w-40" src="images/troes-icon.png" alt="Troes Icon" />
      </div>
      <div className="flex-none gap-2">
        <Dropdown
          name="Systems"
          items={systems}
          idKey="system_id"
          nameKey="system_name"
          selected={selectedSystem}
          onChange={setSelectedSystem}
          isLoading={loading}
          noItemsMessage="No Systems Found!"
        />
        <div className="dropdown dropdown-end">
          <div
            tabIndex={0}
            role="button"
            className="btn btn-ghost btn-circle avatar"
          >
            <div className="w-10 rounded-full">
              <UserCircleIcon />
            </div>
          </div>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow"
          >
            <li className="pointer-events-none text-yellow-500">
              <div className="font-bold">{authData?.email}</div>
            </li>
            <li onClick={logout}>
              <div>Logout</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
