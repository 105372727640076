import React, { useRef, useEffect } from "react";
import { NoSymbolIcon } from "@heroicons/react/24/outline";

// items = [{name: "Display 1", key: '123'}]
function Dropdown(props) {
  const {
    name = "",
    items = [],
    idKey = "id",
    nameKey = "name",
    selected,
    onChange = () => {},
    loading = false,
    noItemsMessage = "No Data Found!",
  } = props;

  const detailsRef = useRef(null);

  const handleClickOutside = (event) => {
    if (detailsRef.current && !detailsRef.current.contains(event.target)) {
      detailsRef.current.open = false;
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const RenderItems = () => {
    return (
      <ul
        tabIndex={0}
        className="dropdown-content menu bg-base-100 rounded-box z-[11] w-52 p-2 shadow"
      >
        {loading && <span className="loading loading-dots loading-md"></span>}
        {items?.map((item) => (
          <li
            className="cursor-pointer px-3 py-1"
            key={item[idKey]}
            onClick={() => {
              onChange(item);
              detailsRef.current.open = false;
            }}
          >
            {item[nameKey]}
          </li>
        ))}
        {!items?.length && (
          <li className="flex-row justify-center items-center">
            <span className="ps-0 pe-2">
              <NoSymbolIcon className="size-5" />
            </span>{" "}
            {noItemsMessage}
          </li>
        )}
      </ul>
    );
  };

  return (
    <ul className="menu menu-horizontal px-1">
      <li>
        <details ref={detailsRef}>
          <summary>{(selected && selected[nameKey]) || name}</summary>
          <RenderItems />
        </details>
      </li>
    </ul>
  );
}

export default Dropdown;
