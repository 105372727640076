import SVGS from "../../utils/lib/svgs";
import URLS from "../../utils/lib/urls";

function Footer() {
  return (
    <footer className="footer bg-troesBlue text-opacity-90 text-white p-10 gap-y-4">
      <aside>
        <img className="w-40" src="images/troes-icon.png" alt="Troes Icon" />
        <p className="font-bold">
          TROES Corp.
          <br />
          A technology firm serving renewable and microgrid battery energy
          storage solutions within the commercial, industrial and institutional
          field.
          <br /> <br />
          401 Bentley St. Unit 3, Markham ON, Canada, L3R 9T2
          <br />
          <a href="tel:+1 888-998-7637" className="text-yellow-400">
            +1 888-998-7637
          </a>
        </p>
        <p>
          Copyright {new Date().getFullYear()} © TROES Corp. - All right
          reserved
        </p>
      </aside>
      <nav>
        <h6 className="footer-title">Social</h6>
        <div className="grid grid-flow-col gap-4">
          <a
            href={URLS.YOUTUBE_URL}
            target="_blank"
            rel="noreferrer"
          >
            <SVGS.Youtube />
          </a>
          <a
            href={URLS.LINKEDIN_URL}
            target="_blank"
            rel="noreferrer"
          >
            <SVGS.Linkedin />
          </a>
        </div>
      </nav>
    </footer>
  );
}

export default Footer;
