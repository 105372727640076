import React, { createContext, useContext, useState, useEffect } from 'react';
import request from '../utils/helpers/apiHandler';
import URLS from '../utils/lib/urls';
import { isTimestampOlderThan10Minutes } from '../utils/helpers/common';
import { useNavigate } from 'react-router-dom';
import { paths } from '../utils/lib/routes';

export const AuthContext = createContext();

// Custom authentication hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

// Provider component
export const AuthProvider = ({ children }) => {
  const token = localStorage.getItem("token");

  const [isAuthenticated, setIsAuthenticated] = useState(!(token === null || token === "undefined"));
  const [authData, setAuthData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const uid = localStorage.getItem('uid');
    const email = localStorage.getItem('email');

    if (!isTimestampOlderThan10Minutes()) {
      setAuthData({ token, uid, email });
      setIsAuthenticated(!!token);
    } else {
      setAuthData({});
      setIsAuthenticated(false);
    }
  }, []);

  const login = async (email, password) => {
    setLoading(true);
    const { data, error } = await request('POST', URLS.LOGIN, { email, password }, false);

    localStorage.setItem('token', data?.token);
    localStorage.setItem('uid', data?.uid);
    localStorage.setItem('email', data?.email);
    localStorage.setItem('timestamp', Date.now());
    setAuthData(data || {});
    setIsAuthenticated(!error);
    setError(error);
    setLoading(false);
    if (!error) navigate(paths.HOME, { replace: true });
  };

  const logout = async () => {
    setLoading(true);
    await request('GET', URLS.LOGOUT);
    localStorage.removeItem('token');
    localStorage.removeItem('uid');
    localStorage.removeItem('email');
    localStorage.removeItem('timestamp');
    setAuthData({});
    setIsAuthenticated(false);
    setError(error);
    setLoading(false);
    navigate(paths.LOGIN, { replace: true });
  };

  return (
    <AuthContext.Provider value={{
      isAuthenticated, login, logout,
      authData, loading, error, setError
    }}>
      {children}
    </AuthContext.Provider>
  );
};
