import { Routes, Route, useLocation } from 'react-router-dom';

import './assets/styles/App.scss';
import withAuth from './hocs/withAuth';
import routes from './utils/lib/routes';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

function App() {
  const location = useLocation();
  const protectedRoutes = routes.filter(route => route.isProtected).map(route => route.path);

  const RenderRoutes = () => {
    return routes.map((route) => {
      const Component = route.isProtected ? withAuth(route.component) : route.component;
      const Element = () => <div className='h-full'><Component /></div>

      return <Route key={route.path} path={route.path} element={<Element />} />;
    });
  }

  return (
    <div data-theme="dark" className="troes-accounts-app font-barlow h-screen flex flex-col bg-zinc-700">
      {protectedRoutes.includes(location.pathname) && <Navbar />}

      <Routes>
        {RenderRoutes()}
      </Routes>

      {protectedRoutes.includes(location.pathname) && <Footer />}
    </div>
  );
}

export default App;
