import Home from "../../views/Home";
import Login from "../../views/Login";

export const paths = {
  LOGIN: '/login',
  HOME: '/',
};

const routes = [
  {
    name: "Home",
    path: paths.HOME,
    isProtected: true,
    component: Home,
  },
  {
    name: "Login",
    path: paths.LOGIN,
    isProtected: false,
    component: Login,
  }
];

export default routes;