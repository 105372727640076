import axios from 'axios';

const request = async (method, url, data = null, isProtected = true, config = {}) => {
  const token = localStorage.getItem('token');
  const uid = localStorage.getItem('uid');

  config.headers = {
    ...config.headers,
    'x-user-id': uid,
  };

  try {
    const headers = isProtected && token
      ? {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      }
      : config.headers;

    const response = await axios({
      method,
      url,
      data,
      ...config,
      headers,
    });

    return { data: response.data, error: null };
  } catch (error) {
    return { data: null, error: error?.response?.data?.error || error?.message, message: error?.message };
  }
};

export default request;
