import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EnvelopeIcon, KeyIcon } from "@heroicons/react/24/outline";
import { useAuth } from "../contexts/AuthContext";
import { paths } from "../utils/lib/routes";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const { login, error, setError, isAuthenticated, loading } =
    useAuth();

  const handleClickLogin = async () => {
    await login(email, password);
  };

  useEffect(() => {
    if (isAuthenticated && !loading) navigate(paths.HOME, { replace: true });
  }, []);

  return (
    <div className="login w-full h-full text-white relative">
      {/* Background Image */}
      <img
        alt=""
        src="images/login-bg.jpg"
        className="h-full w-full absolute bg-cover bg-center bg-no-repeat brightness-50"
      ></img>

      <div className="relative z-10 h-full flex justify-center items-center">
        {/* Card */}
        <div className="card glass xs:w-[90%] md:w-[70%] h-[80%] lg:h-auto xl:h-[82%] shadow-xl xs:p-0 md:p-5 xl:p-20">
          {/* Card Body */}
          <div className="card-body flex-col justify-center xl:flex-row xs:p-5 sm:p-8 text-center xl:text-left">
            {/* Text Section */}
            <div className="w-[100%] xl:w-[60%] content-center xs:pb-0 xl:pb-10 xs:pe-0 xl:pe-5">
              <img
                src="images/troes-icon.png"
                alt=""
                className="xs:mb-4 md:mb-10 xs:w-[100%] md:w-[65%] mx-auto xl:mx-0"
              />
              <div className="text-2xl md:text-3xl lg:text-5xl xl:text-5xl font-bold xs:mb-4 xl:mb-14">
                All-in-One Modular Battery Energy Storage Systems
              </div>
              <span className="text-yellow-500 text-l md:text-2xl lg:text-4xl xl:text-4xl font-semibold xs:hidden xl:block">
                for Behind-the-Meter and Microgrid Solutions
              </span>
            </div>

            {/* Login Form Section */}
            <div className="w-[100%] xl:w-[40%] content-center xs:px-0 md:px-5">
              <h1 className="justify-center flex-col xs:text-2xl md:text-3xl font-bold xs:mb-1 md:mb-5">
                Login to your Account
              </h1>
              <div className="label">
                <span className="label-text">Email</span>
              </div>
              <label className="input input-bordered flex items-center gap-2">
                <EnvelopeIcon className="size-5" />
                <input
                  type="text"
                  className="grow"
                  placeholder="xyz@email.com"
                  value={email}
                  onChange={(e) => {
                    setEmail(e?.target?.value);
                    setError("");
                  }}
                />
              </label>
              <div className="label xs:mt-0 md:mt-2">
                <span className="label-text">Password</span>
              </div>
              <label className="input input-bordered flex items-center gap-2">
                <KeyIcon className="size-5" />
                <input
                  type="password"
                  className="grow"
                  placeholder="******"
                  value={password}
                  onChange={(e) => {
                    setPassword(e?.target?.value);
                    setError("");
                  }}
                />
              </label>
              <div className="card-actions justify-center ">
                <button
                  className="yellow-btn xs:mt-5 md:mt-10"
                  onClick={handleClickLogin}
                >
                  Login
                </button>
              </div>
              {error && (
                <div className="glass text-red-600 font-semibold text-center p-2 xs:mt-5 md:mt-10 rounded-md">
                  {error}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
