function isTimestampOlderThan10Minutes() {
  const currentTime = Date.now();
  const storedTimestamp = localStorage.getItem('timestamp');

  if (!storedTimestamp || storedTimestamp === "undefined") return true;
  
  const timeDifference = currentTime - parseInt(storedTimestamp, 10);
  return timeDifference > 600000;
}

export {
  isTimestampOlderThan10Minutes
};
